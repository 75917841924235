<template>
  <div>
    <div v-if="getList.length === 0">
      No registrations
    </div>
    <div v-else>
      <div class="w-8/12 mx-auto border-1">
        <div
            class="pb-4 mb-8 border-b"
            v-for="registration in getList" :key="registration.id + '-registration'">
          <div>
            <h3 class="text-xl">Registration to event: {{ registration.event.title }}</h3>
            <small class="text-gray-500">Registration date: {{ getRegistrationDate(registration) }}</small>
            <div class="flex mt-4">
              <div class="w-1/4 p-4 border-2 rounded">
                <div class="text-center">
                  Form: <span class="font-bold">{{ registration.form.title }}</span>
                </div>
                <div class="text-center"> {{ registration.details.last_name }} {{
                    registration.details.first_name
                  }}
                </div>
                <div class="text-center">
                  <button type="button" class="inline-block mx-auto btn btn-primary">
                    View details (inactive)
                  </button>
                </div>
              </div>

              <div class="w-1/4 p-4 border-2 rounded" v-for="subRegistrant in registration.children"
                   :key="subRegistrant.id + '-subregistrant'">
                <div class="text-center">
                  Form: <span class="font-bold">{{ subRegistrant.form.title }}</span>
                </div>
                <div class="text-center"> {{ subRegistrant.details.last_name }} {{
                    subRegistrant.details.first_name
                  }}
                </div>
                <div class="text-center">
                  <button type="button" class="inline-block mx-auto btn btn-primary">
                    View details (inactive)
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'

export default {
  name: 'MyRegistrations',
  computed: {
    ...mapGetters('myRegistrations', ['getList'])
  },
  created () {
    this.loadList()
  },
  methods: {
    ...mapActions('myRegistrations', ['loadList']),
    getRegistrationDate (registration) {
      console.log(registration)
      return registration ? moment(registration.created_at).format(registration.event.date_format.js) : null
    },
  }
}
</script>

<style scoped>

</style>